
import {
  IonPage,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuToggle,
  IonSearchbar,
  IonToolbar,
  IonHeader,
} from '@ionic/vue';
import { defineComponent, ref, computed } from 'vue';
import {
  mic,
  peopleCircle,
  calendar,
  mapSharp,
  informationCircleSharp,
  logoTwitter,
  clipboardSharp,
  notificationsSharp,
} from 'ionicons/icons';

export default defineComponent({
  name: 'App',
  components: {
    IonHeader,
    IonToolbar,
    IonPage,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenuToggle,
    IonSearchbar,
  },
  setup() {
    const selectedIndex = ref(-1);
    const search = ref<string>('');
    const appPages = [
      {
        title: 'Exhibitors',
        url: '/exhibitors',
        icons: peopleCircle,
      },
      {
        title: 'Speakers',
        url: '/speakers',
        icons: mic,
      },
      {
        title: 'Agenda',
        url: '/agenda',
        icons: calendar,
      },
      {
        title: 'Map',
        url: '/map',
        icons: mapSharp,
      },
      {
        title: 'Notifications',
        url: '/notifications',
        icons: notificationsSharp,
      },
      {
        title: 'Twitter',
        url: '/twitter',
        icons: logoTwitter,
      },
      {
        title: 'Survey',
        url: '/survey',
        icons: clipboardSharp,
      },
      {
        title: 'About us',
        url: '/about',
        icons: informationCircleSharp,
      },
    ];

    const searchAppPages = computed(() => {
      return appPages.filter((page) => {
        return page.title.toLowerCase().match(new RegExp(search.value, 'i'));
      });
    });
    return {
      selectedIndex,
      appPages,
      search,
      searchAppPages,
    };
  },
});
