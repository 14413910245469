import bizvento from '@/services/bizvento';

export default class NotificationService {
  static async getDelegatePrivateNotification(eventId: string, userId: string) {
    const delegatePrivateNotification =
      await bizvento.delegate.getPrivateNotification(eventId, userId);
    return delegatePrivateNotification;
  }
  static async getNotifications(eventId: string) {
    const notifications = await bizvento.notification.getAll(eventId);
    return notifications;
  }
  static async readNotification(eventId: string, notificationId: string) {
    const notifications = await bizvento.notification.read(
      eventId,
      notificationId
    );
    return notifications;
  }
  static async syncUnread(eventId: string) {
    const notifications = await bizvento.event.syncUnread(eventId);
    return notifications;
  }
}
