import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_IonSplitPane = _resolveComponent("IonSplitPane")!
  const _component_IonApp = _resolveComponent("IonApp")!

  return (_openBlock(), _createBlock(_component_IonApp, null, {
    default: _withCtx(() => [
      _createVNode(_component_IonSplitPane, { "content-id": "main-content" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_menu, {
            "content-id": "main-content",
            type: "overlay",
            "swipe-gesture": "false"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_content, null, {
                default: _withCtx(() => [
                  _createVNode(_component_SideBar),
                  _createVNode(_component_ion_refresher, {
                    slot: "fixed",
                    onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.doRefresh($event)))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_refresher_content, {
                        "pulling-icon": _ctx.chevronDownCircleOutline,
                        "pulling-text": "Pull to refresh",
                        "refreshing-spinner": "bubbles",
                        "refreshing-text": "Refreshing..."
                      }, null, 8, ["pulling-icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_router_outlet, { id: "main-content" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}