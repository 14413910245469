import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/exhibitors',
    component: () => import('../views/Exhibitors.vue'),
  },
  {
    path: '/exhibitors/:id',
    component: () => import('../views/ExhibitorProfile.vue'),
  },
  {
    path: '/agenda',
    component: () => import('../views/Agenda.vue'),
  },
  {
    path: '/agenda/:id',
    component: () => import('../views/AgendaDetails.vue'),
  },
  {
    path: '/speakers',
    component: () => import('../views/Speakers.vue'),
  },
  {
    path: '/speakers/:id',
    component: () => import('../views/SpeakerProfile.vue'),
  },
  {
    path: '/about',
    component: () => import('../views/AboutUs.vue'),
  },
  {
    path: '/map',
    component: () => import('../views/Map.vue'),
  },
  {
    path: '/twitter',
    component: () => import('../views/Twitter.vue'),
  },
  {
    path: '/floor-plan',
    component: () => import('../views/FloorMap.vue'),
  },
  {
    path: '/notifications',
    component: () => import('../views/Notifications.vue'),
  },
  {
    path: '/survey',
    redirect: () => {
      window.open('https://www.surveymonkey.co.uk/r/digitexpo2021', '_system');
      return '/';
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
