import { toastController } from '@ionic/vue';

const openToast = async (
  message: string,
  duration: number,
  position: 'bottom' | 'middle' | 'top',
  color?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'light'
    | 'medium'
    | 'dark'
) => {
  const toast = await toastController.create({
    message,
    duration,
    color: color ? color : 'primary',
    position,
  });
  return toast.present();
};

export { openToast };
