import bizvento from '@/services/bizvento';
import { BizventoService } from '@bizvento/sdk';

export default class AuthService {
  user: any;

  constructor(private _bds = bizvento as BizventoService) {}

  joinEvent(eventId: string, userToken: string, provider: string) {
    return this._bds.event.join(eventId, userToken, provider);
  }
  getSpeakers(eventId: string) {
    return this._bds.speaker.getAll(eventId);
  }
  getExhibitors(eventId: string) {
    return this._bds.exhibitor.getAll(eventId);
  }
  getFavourites(eventId: string, userId: string) {
    return this._bds.user.getFavourites(eventId, userId);
  }
  getAgendaSessions(eventId: string) {
    return this._bds.agenda.getSessions(eventId);
  }
  getAgendaItems(eventId: string) {
    return this._bds.agenda.getAll(eventId);
  }
  getMaps(eventId: string) {
    return this._bds.venue.getMaps(eventId);
  }
}
