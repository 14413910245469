import bizvento from '@/services/bizvento';

export default class VenueService {
  static async getMap(eventId: string) {
    const map = await bizvento.venue.getMaps(eventId);
    return map;
  }
  static async getRooms(eventId: string) {
    const map = await bizvento.venue.getRooms(eventId);
    return map;
  }
}
