
import { App } from '@capacitor/app';
import { Network } from '@capacitor/network';
/* eslint-disable @typescript-eslint/camelcase */
import {
  IonApp,
  IonContent,
  IonMenu,
  IonRouterOutlet,
  IonSplitPane,
  alertController,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
import SideBar from '@/components/SideBar.vue';
import { signInAnonymously } from 'firebase/auth';
import { useStore } from 'vuex';
import {
  UserFavourite,
  AgendaSession,
  Agenda,
  VenueRoom,
  Notification,
} from '@/types/interface';
import { openToast } from '@/helpers/toast';
import { auth } from '@/helpers/firebase';
import AuthService from '@/services/auth.service';
import bizvento from '@/services/bizvento';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import VenueService from '@/services/venue.service';
import NotificationService from '@/services/notification.service';
import { useRouter } from 'vue-router';
import {
  getMessaging,
  onMessage,
  getToken,
  isSupported,
} from 'firebase/messaging';
import { chevronDownCircleOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonContent,
    SideBar,
    IonMenu,
    IonRouterOutlet,
    IonSplitPane,
    IonRefresher,
    IonRefresherContent,
  },

  setup() {
    const anonUser = ref();
    const event = ref();
    const speakers = ref();
    const exhibitors = ref();
    const favourites = ref<UserFavourite[]>();
    const store = useStore();
    const agenda = ref<Agenda[]>();
    const agendaSessions = ref<AgendaSession[]>([]);
    const venueRooms = ref<VenueRoom[]>([]);
    const notifications = ref<Notification[]>([]);
    const notificationStats = ref();
    const router = useRouter();
    const authService = new AuthService();
    let isRefreshRunning = false;

    /**
     * @returns Promise
     */

    const handleAnonymousSignIn = async (): Promise<any> => {
      if (!store.state.user) {
        store.commit('SET_LOADING', true);
      }
      try {
        anonUser.value = await signInAnonymously(auth);
        store.commit('SET_USER', anonUser.value.user);

        event.value = await authService.joinEvent(
          process.env.VUE_APP_EVENT_ID,
          anonUser.value.user.accessToken,
          'anonymous'
        );
      } catch (error) {
        store.commit('SET_LOADING', false);
      } finally {
        store.commit('SET_LOADING', false);
      }
    };

    const getSpeakers = async (eventId: string): Promise<any> => {
      try {
        speakers.value = await authService.getSpeakers(eventId);
      } catch (error) {
        console.error(error);
      }
    };

    const getExhibitors = async (eventId: string): Promise<any> => {
      try {
        exhibitors.value = await authService.getExhibitors(eventId);
      } catch (error) {
        console.error(error);
      }
    };
    const getFavourites = async (
      eventId: string,
      userId: string
    ): Promise<any> => {
      try {
        favourites.value = await await authService.getFavourites(
          eventId,
          userId
        );
      } catch (error) {
        console.error(error);
      }
    };

    const handleNotification = async () => {
      const notificationStat =
        await NotificationService.getDelegatePrivateNotification(
          process.env.VUE_APP_EVENT_ID as string,
          store.state.user.uid
        );
      notificationStats.value = notificationStat;
      store.commit('SET_NOTIFICATION_STAT', notificationStat);

      notifications.value = await NotificationService.getNotifications(
        process.env.VUE_APP_EVENT_ID as string
      );
      store.commit('SET_NOTIFICATIONS', notifications.value);
    };

    const readNotification = async (notificationId: SVGStringList) => {
      const res = await NotificationService.readNotification(
        process.env.VUE_APP_EVENT_ID as string,
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        notificationId
      );
      if (res) {
        store.commit('MARK_NOTIFICATION_AS_READ', notificationId);
      }

      if (Capacitor.isNativePlatform()) {
        await PushNotifications.removeAllDeliveredNotifications();
      }
    };

    const handleBackgroundNotification = async (): Promise<any> => {
      // Show us the notification payload if the app is open on our device
      if (Capacitor.isNativePlatform()) {
        PushNotifications.addListener(
          'pushNotificationReceived',
          async (incomingNotification: PushNotificationSchema) => {
            const toast = await alertController.create({
              header: 'Incoming notification',
              subHeader: incomingNotification.title,
              message: incomingNotification.body,
              cssClass: 'my-custom-class',
              buttons: [
                {
                  text: 'Close',
                  role: 'cancel',
                  cssClass: 'secondary',
                },
                {
                  text: 'View',
                  handler: async () => {
                    await handleNotification();
                    await readNotification(
                      incomingNotification.data.notification_id
                    );
                    router.push('/notifications');
                  },
                },
              ],
            });
            await toast.present();
          }
        );

        PushNotifications.addListener(
          'pushNotificationActionPerformed',
          async (notification: ActionPerformed) => {
            await handleNotification();
            await readNotification(
              notification.notification.data.notification_id
            );
            router.push('/notifications');
          }
        );
      }
    };

    const pushNotificationInit = async (): Promise<any> => {
      let token;
      if (Capacitor.getPlatform() === 'web' && isSupported()) {
        try {
          const messaging = getMessaging();
          const currentToken = await getToken(messaging, {
            vapidKey: process.env.VUE_APP_FCM_VAPID_KEY,
          });
          if (currentToken) {
            token = currentToken;
            await bizvento.user.createSettingsNotification(
              store.state.user.uid,
              {
                id: '',
                native_token: '',
                web_token: token,
              }
            );
            onMessage(messaging, async (payload: any) => {
              const toast = await alertController.create({
                header: 'Incoming notification',
                subHeader: payload.notification.title,
                message: payload.notification.body,
                buttons: [
                  {
                    text: 'Close',
                    role: 'cancel',
                    cssClass: 'secondary',
                  },
                  {
                    text: 'View',
                    handler: async () => {
                      await handleNotification();
                      await readNotification(payload.data.notification_id);
                      router.push('/notifications');
                    },
                  },
                ],
              });
              toast.present();
            });
          } else {
            alert(
              'No registration token available. Request permission to generate one.'
            );
          }
        } catch (err) {
          console.error('An error occurred while retrieving token. ', err);
        }
      }
      if (Capacitor.isNativePlatform()) {
        await PushNotifications.requestPermissions();
        PushNotifications.register();

        PushNotifications.addListener('registrationError', (error) => {
          alert(`error on register ${JSON.stringify(error.error)}`);
        });

        await PushNotifications.addListener(
          'registration',
          async (fcmToken: Token) => {
            token = fcmToken.value;
            if (token) {
              await bizvento.user.createSettingsNotification(
                store.state.user.uid,
                {
                  id: '',
                  native_token: token,
                  web_token: '',
                }
              );
            }
          }
        );
      }
    };

    const getAgendaSessions = async (eventId: string): Promise<any> => {
      try {
        agendaSessions.value = await authService.getAgendaSessions(eventId);
      } catch (error) {
        console.error(error);
      }
    };

    const getAgendaSessionsColorById = (
      sessionId: unknown
    ): Agenda | unknown => {
      const agenda = agendaSessions.value.find((agnd) => agnd.id == sessionId);

      if (agenda) {
        return agenda;
      }
      return '';
    };

    const getAgendaRoomById = (roomId: string): VenueRoom | any => {
      const room = venueRooms.value.find((room) => room.id === roomId);
      if (room) {
        return room;
      }
      return '';
    };

    const getAgendaItems = async (eventId: string): Promise<any> => {
      try {
        const data = await authService.getAgendaItems(eventId);

        const agendaSessionsById = data.map(async (agd: Agenda | any) => {
          const newagd: any = await getAgendaSessionsColorById(agd.session_id);
          agd.colour = newagd.colour;
          agd.isChecked = true;
          agd.sessionName = newagd.name;
          const agendaRoom = await getAgendaRoomById(agd.room_id);
          agd.roomName = agendaRoom.name;
        });

        await Promise.all([agendaSessionsById, getAgendaRoomById]);

        agenda.value = data;
      } catch (error) {
        console.error(error);
      }
    };

    const getEventRooms = async () => {
      venueRooms.value = await VenueService.getRooms(
        process.env.VUE_APP_EVENT_ID as string
      );
      store.commit('SET_VENUE_ROOMS', venueRooms.value);
    };

    const onMountedRequest = async () => {
      if (event.value && anonUser.value) {
        await handleNotification();
        await getAgendaSessions(process.env.VUE_APP_EVENT_ID);
        store.commit('SET_AGENDA_SESSIONS', agendaSessions.value);

        await getEventRooms();

        await getAgendaItems(process.env.VUE_APP_EVENT_ID);
        store.commit('SET_AGENDA', agenda.value);

        store.commit('SET_EVENT', event.value);

        await getSpeakers(process.env.VUE_APP_EVENT_ID);
        store.commit('SET_SPEAKERS', speakers.value);

        await getExhibitors(process.env.VUE_APP_EVENT_ID);
        store.commit('SET_EXHIBITORS', exhibitors.value);

        await getFavourites(process.env.VUE_APP_EVENT_ID, store.state.user.uid);
        store.commit('SET_FAVOURITES', favourites.value);
      }
    };

    const reinitialiseApp = async () => {
      try {
        if (!isRefreshRunning) {
          isRefreshRunning = true;
          if (!store.state.user) {
            await handleAnonymousSignIn();
            handleBackgroundNotification();
            pushNotificationInit();
          }
          await onMountedRequest();
        }
      } catch (e) {
        console.log(e);
      } finally {
        isRefreshRunning = false;
      }
    };

    onMounted(async () => {
      const networkStatus = await Network.getStatus();

      if (!store.state.user && !networkStatus.connected) {
        openToast(
          'Internet connection required to join event.',
          10000,
          'bottom',
          'warning'
        );
      }

      await handleAnonymousSignIn();
      handleBackgroundNotification();
      pushNotificationInit();
      onMountedRequest();
    });

    Network.addListener('networkStatusChange', async (status) => {
      if (status.connected) {
        await reinitialiseApp();
      }
    });

    const doRefresh = async (event: any) => {
      await reinitialiseApp();
      event.target.complete();
    };

    App.addListener('appStateChange', async ({ isActive }) => {
      if (isActive) {
        await reinitialiseApp();
      }
    });

    return { chevronDownCircleOutline, doRefresh };
  },
});
