/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable @typescript-eslint/camelcase */

import { VenueRoom } from './../types/interface';
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import {
  Agenda,
  Speaker,
  UserFavourite,
  Exhibitor,
  AgendaSession,
  Notification,
} from '@/types/interface';

export default createStore({
  plugins: [
    createPersistedState({
      key: 'bizvento',
      paths: [
        'user',
        'speakers',
        'exhibitors',
        'agenda',
        'favouriteSpeakers',
        'favourites',
        'favouriteExhibitors',
        'favouriteAgenda',
        'agendaSessions',
        'venueRooms',
        'notifications',
        'notificationStat',
      ],
    }),
  ],
  state: {
    isLoading: false as boolean,
    fcmToken: '' as string,
    agenda: [] as Agenda[],
    user: null,
    event: null,
    speakers: [] as Speaker[],
    exhibitors: null,
    favouriteSpeakers: [] as Speaker[],
    favouriteExhibitors: [] as Exhibitor[],
    favourites: [] as UserFavourite[],
    favouriteAgenda: [] as Agenda[],
    agendaSessions: null as AgendaSession[] | null,
    venueRooms: null as VenueRoom[] | null,
    notifications: null as Notification[] | null,
    notificationStat: null as null | {},
  },
  getters: {
    getFavouriteBySpeaker: (state): UserFavourite[] =>
      state.favourites.filter((favourite) => favourite.type === 'speaker'),
    //  speakers.value.filter((speaker: Speaker) =>
    //   store.getters.getFavouriteBySpeaker.some(
    //     (favSpeaker: UserFavourite) => favSpeaker.entity_id === speaker.id
    //   )
    // );
    // getAgendaById: (state): AgendaSession[] => {
    //   return state.agenda.filter((agnd) => {
    //     return state.agendaSessions.some((agndSession) => )
    //   })

    // }
    getIsLoading: (state): boolean => state.isLoading,
  },
  mutations: {
    SET_LOADING(state, payload: boolean) {
      state.isLoading = payload;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_AGENDA(state, agenda: Agenda[]) {
      state.agenda = agenda;
    },
    SET_EVENT(state, event) {
      state.event = event;
    },
    SET_SPEAKERS(state, speakers) {
      state.speakers = speakers;
    },
    SET_EXHIBITORS(state, exhibitors) {
      state.exhibitors = exhibitors;
    },
    SET_FAVOURITES(state, favourites) {
      state.favourites = favourites;
    },
    SET_FAVOURITE_SPEAKERS(state, speakers: Speaker) {
      state.favouriteSpeakers.push(speakers);
    },
    REMOVE_FAVOURITE_SPEAKER(state, speakersPayload: Speaker) {
      const index = state.favouriteSpeakers.findIndex(
        (speaker) => speaker.id === speakersPayload.id
      );
      state.favouriteSpeakers.splice(index, 1);
    },
    SET_FAVOURITE_EXHIBITOR(state, exhibitors: Exhibitor) {
      state.favouriteExhibitors.push(exhibitors);
    },
    REMOVE_FAVOURITE_EXHIBITOR(state, exhibitorPayload: Exhibitor) {
      const index = state.favouriteExhibitors.findIndex(
        (exhibitor) => exhibitor.id === exhibitorPayload.id
      );
      state.favouriteExhibitors.splice(index, 1);
    },
    SET_FAVOURITE_AGENDA(state, agenda: Agenda) {
      state.favouriteAgenda.push(agenda);
    },
    REMOVE_FAVOURITE_AGENDA(state, agendaPayload: Agenda) {
      const index = state.favouriteAgenda.findIndex(
        (agenda) => agenda.id === agendaPayload.id
      );
      state.favouriteAgenda.splice(index, 1);
    },
    // FILTER_AGENDA(state, agendaPayload) {
    //   const filteredAgenda = state.agenda.filter((elem) =>
    //     agendaPayload.find((elem2: any) => elem.colour === elem2.colour)
    //   );
    //   console.log(filteredAgenda);

    //   // state.agenda = filteredAgenda;
    // },
    SET_AGENDA_SESSIONS(state, agendaSession: AgendaSession[]) {
      state.agendaSessions = agendaSession;
    },
    SET_FCM_TOKEN(state, fcmToken: string) {
      state.fcmToken = fcmToken;
    },
    SET_VENUE_ROOMS(state, venueRooms: VenueRoom[]) {
      state.venueRooms = venueRooms;
    },
    SET_NOTIFICATIONS(state, notifications: Notification[]) {
      state.notifications = notifications;
    },
    SET_NOTIFICATION_STAT(state, notificationStat) {
      state.notificationStat = notificationStat;
    },
    MARK_NOTIFICATION_AS_READ(state, notificationId) {
      const newArray: any = [];
      const event = {
        notification_ids: [] as any,
      };

      if (!state.notificationStat) {
        event.notification_ids.push(notificationId);
        return (state.notificationStat = event);
      }
      if (
        // @ts-ignore
        !state.notificationStat.event.notification_ids
      ) {
        // @ts-ignore
        (state.notificationStat.event.notification_ids = newArray),
          // @ts-ignore
          state.notificationStat.event.notification_ids.push(notificationId);
      } else {
        // @ts-ignore
        state.notificationStat.event.notification_ids.push(notificationId);
      }
    },
  },
  modules: {},
});
